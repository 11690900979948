<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Project Name</label>
                          <b-form-input v-model="project" placeholder="Enter project" />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group v-if="User == 'admin'">
                          <label>Type</label>
                          <v-select
                            @input="getEmployee($event)"
                            v-model="branchname"
                            placeholder="None"
                            label="name"
                            :options="branchOptions"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4" v-if="branchname && branchname.name !== 'All'">
                        <b-form-group>
                          <label
                            >Select
                            {{
                              branchname.name == "Admin" ? "Employee" : branchname.name
                            }}</label
                          >
                          <v-select
                            v-model="employee"
                            label="fullname"
                            placeholder="None"
                            :options="$store.getters['master/getUsers'](branchname.id)"
                            @input="handleClient"
                          >
                            <template
                              #option="{ name, profile_image, username, surname }"
                            >
                              <b-avatar :src="getprofileImage(profile_image)" />
                              <span class="font-weight-bolder">
                                {{ name }} {{ surname }}</span
                              ><span>({{ username }})</span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Client</label>
                          <v-select
                            v-model="client"
                            label="fullname"
                            placeholder="None"
                            :options="$store.state.master.customersSiteVisit"
                          >
                            <template #option="{ fullname, profile_image }">
                              <b-avatar :src="getprofileImage(profile_image)" />
                              <span class="font-weight-bolder"> {{ fullname }}</span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Vehicle No.</label>
                          <v-select
                            v-model="vehicleno"
                            placeholder="None"
                            :options="$store.state.master.vehicles"
                            label="vehicleno"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Status</label>
                          <v-select
                            v-model="status"
                            placeholder="None"
                            label="name"
                            :options="[
                              'All',
                              'Canceled',
                              'Pending',
                              'Accepted',
                              'Completed',
                            ]"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            value=""
                            class="form-control"
                            placeholder="Select Date"
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                            }"
                            @input="getdata($event)"
                            style="background-color: transparent"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="searchData"
                        :disabled="flag"
                      >
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <customer-modal
                v-if="$store.state.app.customerDetailsModal"
              ></customer-modal>
              <report-table :data="data" :columns="fields"> </report-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import moment from "moment";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BAvatar,
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    vSelect,
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch,
    BAvatar,
    BFormInput,
  },
  data() {
    return {
      flag: false,
      User: "",
      employee: "",
      project: "",
      branchname: "",
      data: "",
      startdate: "",
      enddate: "",
      result: "",
      data: "",
      data1: [],
      users: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "project",
          label: "Project",
        },
        {
          field: "clientname",
          label: "Customer Name",
        },
        {
          field: "source",
          label: "Source",
        },
        {
          field: "manager",
          label: "manager",
        },
        {
          field: "assistant",
          label: "Assistant",
        },
        {
          field: "guestmanager",
          label: "Guest Manager",
        },
        {
          field: "driver",
          label: "Driver",
        },
        {
          field: "stay",
          label: "Stay Location",
        },
        {
          field: "vehicleno",
          label: "Vehicle No.",
        },
        {
          field: "visitdate",
          label: "Arrival Date",
        },
        {
          field: "visitdate",
          label: "Site Visit Date",
        },
        {
          field: "departurdate",
          label: "Departure Date",
        },
        {
          field: "remarks",
          label: "Remarks",
        },
        {
          field: "status",
          label: "Status",
        },
      ],
      projectOption: [],
      plots: [],
      employeeOptions: [],
      status: "",
      branchOptions: [
        { name: "Channel Partner", id: "channelpartner" },
        { name: "Franchise", id: "franchise" },
        { name: "Employee", id: "employee" },
        { name: "All", id: "all" },
      ],
      client: "",
      vehicleno: "",
    };
  },
  watch: {
    "$store.state.app.user_data"(newValue, oldValue) {
      this.User = this.$store.state.app.user_data.role;
    },
  },
  mounted() {
    this.User = this.$store.state.app.user_data.role;
    if (this.User !== "admin") {
      this.getEmployee({ id: this.User });
    }
    this.$store.dispatch("master/getVehicle");
  },
  methods: {
    async getEmployee(e) {
      this.employee = [];
      if (e && e.id == "channelpartner") {
        this.$store.dispatch("master/getChannelpartner");
      } else if (e && e.id == "franchise") {
        this.$store.dispatch("master/getFranchise");
      } else if (e && e.id == "employee") {
        this.$store.dispatch("master/getEmployee");
      }
    },
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    handleClient() {
      if (this.employee && this.employee.id) {
        this.$store.dispatch("master/getCustomerBySiteVisit", this.employee.id);
      }
    },
    async searchData(tableData) {
      const obj = {
        startdate: this.startdate,
        enddate: this.enddate,
        team: this.employee ? this.employee.id : "",
        client: this.client ? this.client.id : "",
        status: this.status,
        vehicleno: this.vehicleno ? this.vehicleno.vehicleno : "",
      };
      this.flag = true;
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(obj),
        url: `${this.baseApi}/sitevisitreport`,
      })
        .then((response) => {
          this.flag = false;

          this.data = response.data.data;

          this.data.map((item, index) => {
            item.visitdate =
              item.visitdate == false || item.visitdate == "00/00/0000"
                ? "-"
                : moment(item.visitdate).format("DD/MM/YYYY");
            item.visitdate += " " + item.visittime;

            item.arrivaldate =
              item.arrivaldate == false || item.arrivaldate == "00/00/0000"
                ? "-"
                : moment(item.arrivaldate).format("DD/MM/YYYY");
            item.arrivaldate += " " + item.arrivaltime;

            item.departurdate =
              item.departurdate == false || item.departurdate == "00/00/0000"
                ? "-"
                : moment(item.departurdate).format("DD/MM/YYYY");
            item.departurdate += " " + item.droptime;

            item.source = item.source ? item.source.fullname : "-";
            item.manager = item.manager ? item.manager.fullname : "-";
            item.assistant = item.assistant ? item.assistant.fullname : "-";
            item.driver = item.driver ? item.driver.fullname : "-";
            item.guestmanager = item.guestmanager ? item.guestmanager.fullname : "-";
            item.clientname = item.clientname ? item.clientname.fullname : "-";
            item.remarks = item.remarks ? item.remarks.remarks : "-";

            if (item.active == 4) {
              item.status = "Completed";
            } else if (item.action == 0) {
              item.status = "Canceled";
            } else if (item.lock == 0) {
              item.status = "Accepted";
            } else {
              item.status = "Pending";
            }
          });
        })
        .catch((error) => {
          this.flag = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
